import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreActionDealsState, TStoreActionDealsTab } from 'store/types';
import { IActionDeal } from 'types/store/storeTypes';

const initialState: IStoreActionDealsState = {
  tabData: {
    ON_JOIN: [],
    JOINED: [],
    ON_SALE: [],
    FINISHED: [],
  },
  error: null,
};

export const storeActionDealsSlice = createSlice({
  name: 'storeActionDeals',
  initialState,
  reducers: {
    getStoreActionDealsRequest: (
      state,
      action: PayloadAction<{ key: TStoreActionDealsTab }>,
    ) => {
      state.error = null;
    },
    getStoreActionDealsSuccess: (
      state,
      action: PayloadAction<{
        key: TStoreActionDealsTab;
        data: { data: IActionDeal[] };
      }>,
    ) => {
      const { key, data } = action.payload;
      const { data: actionDeals } = data;
      state.tabData[key] = actionDeals;
      state.error = null;
    },
    getStoreActionDealsFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getStoreActionDealsRequest,
  getStoreActionDealsSuccess,
  getStoreActionDealsFailure,
} = storeActionDealsSlice.actions;

export default storeActionDealsSlice.reducer;
