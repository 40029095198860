import { all, put, takeLatest } from 'redux-saga/effects';
import {
  getStoreCollectionList,
  getStoreShowcaseList,
  getStoreThemeList,
  postStoreActionDeal,
  getRealTimeUserCountActionDeal,
  setEncoreDeal,
  patchActionDealPurchase,
} from 'api';
import { postBizFollowForActionDeal } from 'api/biz';
import {
  getStoreCollectionListRequest,
  getStoreCollectionListSuccess,
  getStoreCollectionListFailure,
  getStoreShowcaseListRequest,
  getStoreShowcaseListSuccess,
  getStoreShowcaseListFailure,
  getStoreThemeListRequest,
  getStoreThemeListSuccess,
  getStoreThemeListFailure,
  setActionDealFollowRequest,
  setActionDealFollowFailure,
  setActionDealFollowSuccess,
  getActionDealCommentRequest,
  getActionDealCommentFailure,
  getActionDealCommentSuccess,
  joinStoreDealRequest,
  joinStoreDealSuccess,
  joinStoreDealFailure,
  getRealTimeUserCountRequest,
  getRealTimeUserCountSuccess,
  getRealTimeUserCountFailure,
  setEncoreDealRequest,
  setEncoreDealSuccess,
  setEncoreDealFailure,
  patchActionDealPurchaseRequest,
  patchActionDealPurchaseSuccess,
  patchActionDealPurchaseFailure,
} from 'store/camfitStoreHome/reducer';
import { createFetchAction } from 'store/sagas/createFetchAction';
import { getPostCommentsForActionDeal } from 'api/feed';
import { TActionDealType } from 'types/store/storeTypes';

function* getStoreCollectionListSaga() {
  yield takeLatest(
    getStoreCollectionListRequest.type,
    createFetchAction(
      getStoreCollectionList,
      getStoreCollectionListSuccess,
      getStoreCollectionListFailure,
    ),
  );
}

function* getStoreShowcaseListSaga() {
  yield takeLatest(
    getStoreShowcaseListRequest.type,
    createFetchAction(
      getStoreShowcaseList,
      getStoreShowcaseListSuccess,
      getStoreShowcaseListFailure,
    ),
  );
}

function* getStoreThemeListSaga() {
  yield takeLatest(
    getStoreThemeListRequest.type,
    createFetchAction(
      getStoreThemeList,
      getStoreThemeListSuccess,
      getStoreThemeListFailure,
    ),
  );
}

function* setActionDealFollowSaga() {
  yield takeLatest(
    setActionDealFollowRequest.type,
    createFetchAction(
      postBizFollowForActionDeal,
      setActionDealFollowSuccess,
      setActionDealFollowFailure,
      function* success(data: { dealId: string; actionType: TActionDealType }) {
        yield put(
          joinStoreDealRequest({
            dealId: data.dealId,
            actionType: data.actionType,
          }),
        );
      },
    ),
  );
}

function* getActionDealCommentSaga() {
  yield takeLatest(
    getActionDealCommentRequest.type,
    createFetchAction(
      getPostCommentsForActionDeal,
      getActionDealCommentSuccess,
      getActionDealCommentFailure,
    ),
  );
}

function* joinStoreDealSaga() {
  yield takeLatest(
    joinStoreDealRequest.type,
    createFetchAction(
      postStoreActionDeal,
      joinStoreDealSuccess,
      joinStoreDealFailure,
    ),
  );
}

function* getRealTimeUserCountSaga() {
  yield takeLatest(
    getRealTimeUserCountRequest.type,
    createFetchAction(
      getRealTimeUserCountActionDeal,
      getRealTimeUserCountSuccess,
      getRealTimeUserCountFailure,
    ),
  );
}

function* setEncoreDealSaga() {
  yield takeLatest(
    setEncoreDealRequest.type,
    createFetchAction(
      setEncoreDeal,
      setEncoreDealSuccess,
      setEncoreDealFailure,
    ),
  );
}

function* patchActionDealPurchaseSaga() {
  yield takeLatest(
    patchActionDealPurchaseRequest.type,
    createFetchAction(
      patchActionDealPurchase,
      patchActionDealPurchaseSuccess,
      patchActionDealPurchaseFailure,
    ),
  );
}

export default function* camfitStoreHomeSaga() {
  yield all([
    getStoreCollectionListSaga(),
    getStoreShowcaseListSaga(),
    getStoreThemeListSaga(),
    setActionDealFollowSaga(),
    getActionDealCommentSaga(),
    joinStoreDealSaga(),
    getRealTimeUserCountSaga(),
    setEncoreDealSaga(),
    patchActionDealPurchaseSaga(),
  ]);
}
