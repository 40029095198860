import { baseApi } from 'api';
import {
  IFeedCampaign,
  IFeedPostScore,
  IFeedQuration,
  TFeedAPIStatus,
  TFeeds,
  TPostComments,
} from 'types/feed';
import { TReportType } from '@types';
import instance from './instance';

export const getFeedsQuration = async () => {
  const res = await baseApi.get<{
    status: TFeedAPIStatus;
    data: IFeedQuration[];
  }>(`/v1/camp-sang-feeds`);
  return res.data;
};

// 특정 피드 정보 요청
export const getFeedInfoById = async (feedId: string) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: IFeedQuration;
  }>(`/v1/camp-sang-feeds/${feedId}`);
  return res.data;
};

export const getFeedsCampaigns = async () => {
  const res = await baseApi.get<{
    status: TFeedAPIStatus;
    data: IFeedCampaign[];
  }>(`/v1/campaigns`);
  return res.data;
};

/**
 * *********************************************************
 * *********************************************************
 * *********************************************************
 * *************** 이하 로그인 했을 때 사용하는 API ***************
 * *********************************************************
 * *********************************************************
 * *********************************************************
 */

// 피드 요청
export const getPosts = async (params: {
  name: string;
  page: number;
  pageSize: number;
  adPostIds?: string;
}) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => !!value),
  );

  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(`/v1/post`, { params: filteredParams });
  return res.data;
};

// 피드 상세 요청
export const getPost = async (
  id: string,
  feedId?: string,
  bizProfileId?: string,
) => {
  const queryString = new URLSearchParams();

  if (feedId) {
    queryString.append('feedId', feedId);
  }

  if (bizProfileId) {
    queryString.append('bizProfileId', bizProfileId.toString());
  }

  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds;
  }>(`/v1/post/${id}?${queryString.toString()}`);
  return res.data;
};

// 북마크 토글
export const postBookmark = async (id: string, feedId?: string) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/posts/${id}/bookmark`, feedId ? { feedId } : null);
  return res.data;
};

// 리액션 토글
export const postReaction = async (
  id: string,
  reactionId: string,
  feedId?: string,
) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/posts/${id}/reaction`, feedId ? { feedId, reactionId } : { reactionId });
  return res.data;
};

// 포스트 댓글 요청
export const getPostComments = async (
  page: number,
  pageSize: number,
  postId: string,
) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TPostComments[];
  }>(`/post-comments?page=${page}&pageSize=${pageSize}&postId=${postId}`);
  return res.data;
};

// 포스트 댓글 요청 - 액션 딜용
export const getPostCommentsForActionDeal = async ({
  page,
  pageSize,
  postId,
}: {
  page: number;
  pageSize: number;
  postId: string;
}) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TPostComments[];
  }>(`/post-comments?page=${page}&pageSize=${pageSize}&postId=${postId}`);
  return res.data;
};

// 댓글 생성
export const createComment = async (
  postId: string,
  data: FormData,
  feedId?: string,
) => {
  if (feedId) {
    data.append('feedId', feedId);
  }

  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
    data: {
      _id: string;
      action: null | {
        targetLink: string;
        joinMessage: string;
        joinButtonMessage: string;
      };
    };
  }>(`/posts/${postId}/comment`, data);
  return res.data;
};

// 대댓글 생성
export const createThread = async (
  postId: string,
  data: FormData,
  feedId?: string,
) => {
  if (feedId) {
    data.append('feedId', feedId);
  }

  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
    data: any;
  }>(`/posts/${postId}/thread`, data);
  return res.data;
};

// 댓글 or 대댓글 삭제
export const deleteCommentOrThread = async (commendOrThreadId: string) => {
  const res = await instance.delete<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/posts/${commendOrThreadId}/comment`);
  return res.data;
};

// 댓글, 대댓글 리액션 토글
export const toggleCommentOrThreadReaction = async (
  postId: string,
  reactionId: string,
  commentId: string,
  feedId?: string,
) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
  }>(
    `/posts/${postId}/reaction/comment`,
    feedId ? { feedId, reactionId, commentId } : { reactionId, commentId },
  );
  return res.data;
};

// 게시글 작성
export const createPost = async (data: FormData) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message?: string;
    data: any;
  }>('/v1/boards', data);
  return res.data;
};

// 게시글 업데이트
export const updatePost = async (postId: string, data: FormData) => {
  const res = await instance.put<{
    status: TFeedAPIStatus;
    message?: string;
    boardId: string;
    postId?: string;
  }>(`/v1/boards/${postId}`, data);
  return res.data;
};

// 게시글 삭제
export const deletePost = async (postId: string) => {
  const res = await instance.delete<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/posts/${postId}`);
  return res.data;
};

// 게시글 숨기기
export const blockPost = async (postId: string) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/posts/${postId}/block`);
  return res.data;
};

// 이 회원의 글 모두 숨기기
export const blockAllPost = async (blockUserId: string) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message: string;
  }>(`/user-blocks`, { blockUserId });
  return res.data;
};

// 유저 신고하기
// export const reportUser = async (params: {
//   nickname: string;
//   reason: TReportType;
//   additionalReason?: string;
//   link: string;
// }) => {
//   const res = await instance.post<{
//     status: TFeedAPIStatus;
//     message?: string;
//     reportId: string;
//   }>('/v1/user-reports', params);
//   return res.data;
// };

// 게시글 신고하기
export const reportPost = async (params: {
  id: string;
  reason: TReportType;
  additionalReason?: string;
  type: 'post' | 'comment' | 'thread';
}) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message?: string;
    reportId: string;
  }>('/v1/board-reports', params);
  return res.data;
};

// 내 게시글 포스트 가져오기
export const getMyPost = async (page: number, pageSize: number) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(`/posts/me?page=${page}&pageSize=${pageSize}`);
  return res.data;
};

// 내 북마크 포스트 가져오기
export const getBookmarkedPost = async (page: number, pageSize: number) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(`/posts/me/bookmark?page=${page}&pageSize=${pageSize}`);
  return res.data;
};

export const getBookmarkedPostCount = async () => {
  const res = await instance.get<number>(`/v1/me/bookmarks/count`);
  return res.data;
};

// 내 캠핑로그 포스트 가져오기
export const getMyCampingLogPost = async (page: number, pageSize: number) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(`/posts/me/review?page=${page}&pageSize=${pageSize}`);
  return res.data;
};

// 특정 유저의 게시글 가져오기
export const getUserPosts = async (
  nickname: string,
  page: number,
  pageSize: number,
) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(
    `/posts/profile/post?nickname=${nickname}&page=${page}&pageSize=${pageSize}`,
  );
  return res.data;
};

// 특정 유저의 캠핑로그 가져오기
export const getUserCampingLogs = async (
  nickname: string,
  page: number,
  pageSize: number,
) => {
  const res = await instance.get<{
    status: TFeedAPIStatus;
    data: TFeeds[];
  }>(
    `/posts/profile/review?nickname=${nickname}&page=${page}&pageSize=${pageSize}`,
  );
  return res.data;
};

// 유저별 활동 내역 보내기
export const sendPostScore = async (params: IFeedPostScore) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message?: string;
  }>('/post-scores', params);
  return res.data;
};

export const exclusionPost = async (
  feedId: string,
  params: {
    postId: string;
  },
) => {
  const res = await instance.post<{
    status: TFeedAPIStatus;
    message?: string;
  }>(`/camp-sang-feeds/${feedId}/exclusions`, params);
  return res.data;
};
