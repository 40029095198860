import { baseApi, logoutInApp } from 'api';
import {
  BizProfileResponse,
  IBiz,
  IBizChannel,
  IBizFeedsResponse,
  IBizImage,
  IBizMessage,
  IBizProfileStory,
  TBizAPIStatus,
  TBizItemType,
} from 'types/biz';
import { IFeedDiary } from 'types/feed';
import axios, { AxiosAdapter, AxiosInstance, AxiosRequestConfig } from 'axios';
import { getServerURL } from 'server';
import { cacheAdapterEnhancer } from 'axios-extensions';
import { TActionDealType } from 'types/store/storeTypes';
import instance from './instance';

const bizInstance: AxiosInstance = axios.create({
  baseURL: getServerURL(),
  headers: { 'Cache-Control': 'no-cache' },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
    enabledByDefault: false,
  }),
});

bizInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }

  config.headers.Authorization = jwt ? `Bearer ${jwt}` : '';
  return config;
});

// 비즈 프로필 전체 목록 조회
export const getBizProfilePopularList = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const query = new URLSearchParams();
  query.append('skip', skip.toString());
  query.append('limit', limit.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: any[];
  }>(`/v1/biz-profiles/popular?${query.toString()}`);
  return res.data;
};

// 비즈 프로필 나의 목록 조회
export const getBizProfileList = async ({
  skip,
  limit,
}: {
  skip: number;
  limit: number;
}) => {
  const query = new URLSearchParams();
  query.append('skip', skip.toString());
  query.append('limit', limit.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: any[];
  }>(`/v1/biz-profiles?${query.toString()}`);
  return res.data;
};

// 비즈 프로필 홈 조회.
export const getBizProfile = async ({
  id,
  isPreview,
}: {
  id: string;
  isPreview?: string | null;
}) => {
  const queryString = new URLSearchParams();

  if (isPreview === 'true') {
    queryString.append('isPreview', 'true');
  }

  const res = await instance.get<BizProfileResponse>(
    `/v1/biz-profiles/${id}?${queryString.toString()}`,
  );
  return res.data;
};

// 비즈 팔로잉 조회
export const getBizFollowingStatus = async (bizId: string) => {
  const res = await instance.get(`/v1/biz-profiles/${bizId}/following`);
  return res.data;
};

// 비즈 팔로우
export const postBizFollow = async (
  bizId: string,
  isChannelAlarm?: boolean,
) => {
  const body: any = {};

  if (typeof isChannelAlarm === 'boolean') {
    body.isChannelAlarm = isChannelAlarm;
  }
  const res = await instance.post(`/v1/biz-profiles/${bizId}/following`, body);
  return res.data;
};
// 비즈 팔로우 - 액션딜용
export const postBizFollowForActionDeal = async ({
  bizId,
  isChannelAlarm,
  dealId,
  actionType,
}: {
  bizId: string;
  isChannelAlarm?: boolean;
  dealId: string;
  actionType: TActionDealType;
}) => {
  const body: any = {};

  if (typeof isChannelAlarm === 'boolean') {
    body.isChannelAlarm = isChannelAlarm;
  }

  const res = await instance.post(`/v1/biz-profiles/${bizId}/following`, body);

  return { ...res.data, dealId, actionType };
};

// 비즈 프로필 피드 조회
export const getBizFeeds = async (
  bizprofileId: string,
  bizfeedid: string,
  page: number,
  pageSize: number = 20,
) => {
  const params = new URLSearchParams();
  params.append('feedId', bizfeedid);
  params.append('pageSize', pageSize.toString());

  if (page) params.append('page', page.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: IFeedDiary[];
  }>(`/v1/biz-profiles/${bizprofileId}/feeds?${params.toString()}`);
  return res.data;
};

// 비즈 프로필 게시글 작성
export const createBizPost = async (bizprofileId: string, data: FormData) => {
  const res = await instance.post<BizProfileResponse>(
    `/v1/biz-profiles/${bizprofileId}/feeds`,
    data,
  );
  return res.data;
};

// 비즈 스토리 조회
export const getBizProfileStories = async (
  bizProfileId: string,
  page: number = 1,
  pageSize: number = 10,
) => {
  const res = await instance.get<{
    status: TBizAPIStatus;
    data: IBizProfileStory[];
    bizProfile: IBiz;
  }>(`/v1/biz-profiles/${bizProfileId}/stories`, {
    params: {
      page,
      pageSize,
    },
  });
  return res.data;
};

/**
 * *************************
 * 이하 큐레이션
 * *************************
 */

// 큐레이션 상세 조회
export const getCurationDetail = async (
  curationId: string,
  page: number,
  pageSize: number = 30,
) => {
  const params = new URLSearchParams();

  if (typeof page === 'number') params.append('page', page.toString());
  if (typeof pageSize === 'number')
    params.append('pageSize', pageSize.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: any;
    bizProfile: any;
  }>(`/v1/biz-curations/${curationId}?${params.toString()}`);
  return res.data;
};

/**
 * *************************
 * 이하 채널
 * *************************
 */

// 채널 목록
export const getChannelList = async (page: number, pageSize: number = 20) => {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('pageSize', pageSize.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: any[];
  }>(`/v1/biz-channels?${params.toString()}`);
  return res.data;
};

// 메세지 목록 조회
export const getChannelMessages = async (
  channelId: string,
  page: number,
  pageSize: number = 20,
) => {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('pageSize', pageSize.toString());

  const res = await bizInstance.get<{
    status: TBizAPIStatus;
    data: IBizMessage[];
    bizChannel: any;
    bizProfile: any;
    isFollowing: boolean;
    isBlock: boolean;
    isSubscribed: boolean;
    bizChannelUser: {
      isAlarm: boolean;
    };
    bizFollowing: {
      isDeleted: boolean;
      user: string;
      userNickname: string;
    };
  }>(`/v1/biz-channels/${channelId}/messages?${params.toString()}`);
  return res.data;
};

// 채널의 메세지 작성
export const createChannelMessage = async (channelId: string, text: string) => {
  const res = await instance.post<{
    status: TBizAPIStatus;
    data: { message: string };
  }>(`/v1/biz-channels/${channelId}/messages`, { text });
  return res.data;
};

// 채널 앱푸시 토글
export const toggleChannelAppPush = async (channelId: string) => {
  const res = await instance.post<{
    status: TBizAPIStatus;
    data: { message: string };
  }>(`/v1/biz-channels/${channelId}/subscription/alarm`);
  return res.data;
};

// 채널 차단
export const blockChannel = async (channelId: string) => {
  const res = await instance.post<{
    status: TBizAPIStatus;
    data: { message: string };
  }>(`/v1/biz-channels/${channelId}/block`);
  return res.data;
};

// 답장 캠페인 응답
export const createReplyCampaignMessage = async (
  channelId: string,
  campaignReplyTemplateId: string,
) => {
  const res = await instance.post<{
    status: TBizAPIStatus;
    data: { message: string };
  }>(`/v1/biz-channels/${channelId}/campaign`, { campaignReplyTemplateId });
  return res.data;
};

// 브랜드 라운지 조회
export const getFollowingBizProfileList = async ({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}) => {
  const params = new URLSearchParams();
  params.append('page', page.toString());
  params.append('pageSize', pageSize.toString());

  const res = await instance.get<{
    status: TBizAPIStatus;
    data: any[];
  }>(`/v1/biz-profiles/followings?${params.toString()}`);
  return res.data;
};
