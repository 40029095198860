import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStoreHomeState } from 'store/types';
import {
  IActionDeal,
  IStoreCollection,
  IStoreShowcase,
  IStoreTheme,
  TActionDealType,
} from 'types/store/storeTypes';

const initialState: IStoreHomeState = {
  collectionList: [],
  showcaseList: [],
  themeList: [],
  requestStatus: {
    showcaseListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
    themeListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
    collectionListStatus: {
      isRequested: false,
      isLoggedIn: false,
    },
  },
  showcaseIndex: 0,

  actionDealCommentList: [],
  isRecievedCommentResponse: false,
  isRecievedFollowResponse: false,
  isRecievedClickResponse: false,
  clickedActionDealType: 'CLICK',
  error: null,
};

export const camfitStoreHomeSlice = createSlice({
  name: 'camfitStoreHome',
  initialState,
  reducers: {
    getStoreCollectionListRequest: (state, action) => {
      state.error = null;
    },
    getStoreCollectionListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreCollection[] }>,
    ) => {
      state.collectionList = action.payload.data;
    },
    getStoreCollectionListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    getStoreShowcaseListRequest: (state, action) => {
      state.error = null;
      state.showcaseIndex = 0;
    },
    getStoreShowcaseListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreShowcase[] }>,
    ) => {
      state.showcaseList = action.payload.data;
      state.showcaseIndex = 0;
    },
    getStoreShowcaseListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
      state.showcaseIndex = 0;
    },

    getStoreThemeListRequest: (state, action) => {
      state.error = null;
    },
    getStoreThemeListSuccess: (
      state,
      action: PayloadAction<{ data: IStoreTheme[] }>,
    ) => {
      state.themeList = action.payload.data;
    },
    getStoreThemeListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    setRequestStatus: (
      state,
      action: PayloadAction<{
        key: 'showcaseListStatus' | 'themeListStatus' | 'collectionListStatus';
        isRequested: boolean;
        isLoggedIn: boolean;
      }>,
    ) => {
      state.requestStatus[action.payload.key] = action.payload;
    },

    setShowcaseIndex: (state, action: PayloadAction<number>) => {
      state.showcaseIndex = action.payload;
    },

    setActionDealFollowRequest: (
      state,
      action: PayloadAction<{
        bizId?: string;
        isChannelAlarm?: boolean;
        dealId: string;
        actionType: TActionDealType;
      }>,
    ) => {
      state.error = null;
    },
    setActionDealFollowSuccess: (
      state,
      action: PayloadAction<{ data: any }>,
    ) => {
      state.error = null;
      const { isFollowing } = action.payload.data;
    },
    setActionDealFollowFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      console.log('setActionDealFollowFailure 이게 돼???', action.payload);
      state.error = action.payload;
      // state.isRecievedFollowResponse = false;
    },
    getActionDealCommentRequest: (
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
        postId: string;
      }>,
    ) => {
      state.error = null;
    },
    getActionDealCommentSuccess: (
      state,
      action: PayloadAction<{ data: any }>,
    ) => {
      state.actionDealCommentList = action.payload.data;
      state.error = null;
    },
    getActionDealCommentFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },

    joinStoreDealRequest: (
      state,
      action: PayloadAction<{ dealId: string; actionType: TActionDealType }>,
    ) => {
      state.error = null;
    },
    joinStoreDealSuccess: (
      state,
      action: PayloadAction<{ data: IActionDeal }>,
    ) => {
      const { id, status, userCount, detailImages } = action.payload.data;

      const collectionIndex = state.collectionList.findIndex(
        collection =>
          collection.collectionType === 'deal' &&
          collection.collectionSubType === 'actionDeal',
      );

      if (collectionIndex === -1) {
        return;
      }

      const collection = state.collectionList[
        collectionIndex
      ] as IStoreCollection & {
        collectionVariant: { actionDeals: IActionDeal[] };
      };

      const updatedActionDeals =
        collection.collectionVariant.actionDeals?.map(deal =>
          deal.id === id ? { ...deal, status, userCount, detailImages } : deal,
        ) || [];

      state.collectionList[collectionIndex].collectionVariant = {
        ...collection.collectionVariant,
        actionDeals: updatedActionDeals,
      };

      if (
        action.payload.data.action.type === 'COMMENT' &&
        (status === 'JOINED' || status === 'ON_SALE')
      ) {
        state.isRecievedCommentResponse = true;
      } else if (
        action.payload.data.action.type === 'FOLLOW' &&
        (status === 'JOINED' || status === 'ON_SALE')
      ) {
        state.isRecievedFollowResponse = true;
      } else if (
        action.payload.data.action.type === 'CLICK' &&
        (status === 'JOINED' || status === 'ON_SALE')
      ) {
        state.isRecievedClickResponse = true;
      }
    },
    joinStoreDealFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getRealTimeUserCountRequest: (
      state,
      action: PayloadAction<{ dealId: string }>,
    ) => {
      state.error = null;
    },
    getRealTimeUserCountSuccess: (
      state,
      action: PayloadAction<{ dealId: string; userCount: number }>,
    ) => {
      const { dealId, userCount } = action.payload;

      const collectionIndex = state.collectionList.findIndex(
        collection =>
          collection.collectionType === 'deal' &&
          collection.collectionSubType === 'actionDeal',
      );

      if (collectionIndex === -1) {
        return;
      }

      const collection = state.collectionList[
        collectionIndex
      ] as IStoreCollection & {
        collectionVariant: { actionDeals: IActionDeal[] };
      };

      const updatedActionDeals =
        collection.collectionVariant.actionDeals?.map(deal =>
          deal.id === dealId ? { ...deal, userCount } : deal,
        ) || [];

      state.collectionList[collectionIndex].collectionVariant = {
        ...collection.collectionVariant,
        actionDeals: updatedActionDeals,
      };
    },
    getRealTimeUserCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    setIsApplyCompletedActionDeal: (
      state,
      action: PayloadAction<{ actionType: TActionDealType }>,
    ) => {
      if (action.payload.actionType === 'COMMENT') {
        state.isRecievedCommentResponse = false;
      } else if (action.payload.actionType === 'FOLLOW') {
        state.isRecievedFollowResponse = false;
      } else if (action.payload.actionType === 'CLICK') {
        state.isRecievedClickResponse = false;
      }
    },
    setClickedActionDealType: (
      state,
      action: PayloadAction<{ actionType: TActionDealType }>,
    ) => {
      state.clickedActionDealType = action.payload.actionType;
    },
    setEncoreDealRequest: (
      state,
      action: PayloadAction<{ dealId: string }>,
    ) => {
      state.error = null;
    },
    setEncoreDealSuccess: (state, action) => {
      const { id, encoreCount, isEncoreRequested } = action.payload.data;

      const collectionIndex = state.collectionList.findIndex(
        collection =>
          collection.collectionType === 'deal' &&
          collection.collectionSubType === 'actionDeal',
      );

      if (collectionIndex === -1) {
        return;
      }

      const collection = state.collectionList[
        collectionIndex
      ] as IStoreCollection & {
        collectionVariant: { actionDeals: IActionDeal[] };
      };

      const updatedActionDeals =
        collection.collectionVariant.actionDeals?.map(deal =>
          deal.id === id ? { ...deal, encoreCount, isEncoreRequested } : deal,
        ) || [];

      state.collectionList[collectionIndex].collectionVariant = {
        ...collection.collectionVariant,
        actionDeals: updatedActionDeals,
      };
    },
    setEncoreDealFailure: (state, action) => {
      state.error = action.payload;
    },

    patchActionDealPurchaseRequest: (
      state,
      action: PayloadAction<{
        itemNumbers: string[];
        type: 'PURCHASED' | 'CANCELLED';
      }>,
    ) => {
      state.error = null;
    },
    patchActionDealPurchaseSuccess: (state, action) => {
      state.error = null;
    },
    patchActionDealPurchaseFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  getStoreCollectionListRequest,
  getStoreCollectionListSuccess,
  getStoreCollectionListFailure,

  getStoreShowcaseListRequest,
  getStoreShowcaseListSuccess,
  getStoreShowcaseListFailure,

  getStoreThemeListRequest,
  getStoreThemeListSuccess,
  getStoreThemeListFailure,

  setRequestStatus,

  setShowcaseIndex,

  setActionDealFollowRequest,
  setActionDealFollowSuccess,
  setActionDealFollowFailure,

  getActionDealCommentRequest,
  getActionDealCommentSuccess,
  getActionDealCommentFailure,

  joinStoreDealRequest,
  joinStoreDealSuccess,
  joinStoreDealFailure,

  getRealTimeUserCountRequest,
  getRealTimeUserCountSuccess,
  getRealTimeUserCountFailure,
  setIsApplyCompletedActionDeal,
  setClickedActionDealType,

  setEncoreDealRequest,
  setEncoreDealSuccess,
  setEncoreDealFailure,

  patchActionDealPurchaseRequest,
  patchActionDealPurchaseSuccess,
  patchActionDealPurchaseFailure,
} = camfitStoreHomeSlice.actions;

export default camfitStoreHomeSlice.reducer;
