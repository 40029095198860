import { all, takeLatest } from 'redux-saga/effects';
import { createFetchActionWithKey } from 'store/sagas/createFetchAction';
import {
  getStoreActionDealsFailure,
  getStoreActionDealsRequest,
  getStoreActionDealsSuccess,
} from 'store/storeActionDeals/storeActionDealsReducer';
import { getStoreActionDeals } from 'api';

function* getStoreActionDealsSaga() {
  yield takeLatest(
    getStoreActionDealsRequest.type,
    createFetchActionWithKey(
      getStoreActionDeals,
      getStoreActionDealsSuccess,
      getStoreActionDealsFailure,
    ),
  );
}

export default function* storeActionDealsSaga() {
  yield all([getStoreActionDealsSaga()]);
}
